




































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { getFieldError } from '@/core/tools/forms/get_field_error'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'
import ComboboxSelectedObserver from '@/components/Controls/ComboboxSelectedObserver.vue'
// import CompanyDetails from '../../Customers/СompanyDetails/СompanyDetails.vue';

@Component({
  mixins: [validationMixin],
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'serverErrors',
      'form', 'formDirty',
    ]),
  },
  methods: {
    getFieldError,
  },
  validations() {
    const rules: any = {
      address: { required },
      brand_id: { required },
      merchant_id: { required },
      name: { required },
      point: {},
      schedule: {},
      photo_upload_id: {},
      comment: {},
      is_online_shop: {},
      dropFiles: {},
    }

    return {
      form: rules,
    }
  },
  components: {
    TitleBar,
    ComboboxSelectedObserver,
  },
})
export default class OutletForm extends Vue {

  public FILES_HOST = process.env.VUE_APP_UBIC_SERVER_ADDRESS
  public newContact: string = ''
  public newContactType: string = 'phone'

  get isNew() {
    return !!this.$route.params.id
  }

  get titleStack() {
    return [
      'Точки продаж', (this.isNew ? 'Редактирование точки продаж' : 'Новая точка продаж'),
    ]
  }

  public addContact() {
    this.$store.dispatch(`${STORE_KEY}/addContact`, {
      contact: this.newContact, type: this.newContactType,
    })
  }

  public changeValue(field: string, value: any) {
    this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
      field, newValue: value,
    })

    // @ts-ignore
    this.$v.form[field].$touch()
  }

  public submitObject() {
    this.$v.form.$touch()

    if (!this.$v.form.$error) {
      this.$store.dispatch(`${STORE_KEY}/saveObject`, this.$route.params.id)
        .then(() => {
          this.$buefy.snackbar.open(`Данные успешно сохранены`)

          if ('from_observer' in this.$route.params) {
            this.$router.push(
              {
                name: 'merchant-observe',
                params: {
                  id: this.$route.params.from_observer,
                  active_tab: 'outlets',
                },
              },
            )
          }
          else {
            this.$router.push({ name: 'outlet-list' })
          }
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            type: 'is-warning',
            message: `При сохраненит возникли проблемы. Попробуйте позже.`,
          })
        })
    }
  }

  // ---------------------------------------------------------------------------

  public uploadFile(files: File[]) {
    for (const file of files) {
      const formData = new FormData()

      formData.append('file', file)

      // @ts-ignore
      this.$http.post(`/admins/rest/v1/uploads`,
        formData, {
          // emulateJSON: true,
          // headers: {
          //   'Content-Disposition': `form-data; name="photo"; filename="${file.name}"`
          // }
        },
      ).then((response: any) => {
        this.$store.dispatch(`${STORE_KEY}/addAttachments`, response.data.upload)
      })

      // @ts-ignore
      this.$v.form.photo_upload_id.$touch()
    }
  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.$store.dispatch(`${STORE_KEY}/initState`, {
      id: this.$route.params.id,
      predefined: this.$route.query,
    })
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
